// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery")
require("@nathanvda/cocoon")

require("nav");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//= require_tree .
//= require jquery
//= require jquery_ujs

//= require admin-lte/plugins/jQuery/jquery-2.2.3.min
//= require admin-lte/bootstrap/js/bootstrap.min
//= require admin-lte/dist/js/adminlte.js
//= require moment/min/moment.min
//= require admin-lte/dist/js/app.min
//= require admin-lte/plugins/iCheck/icheck

//= require jquery/dist/jquery.min.js
//= require bootstrap/dist/js/bootstrap.min.js
window.addEventListener('turbolinks:load', function () {

  // tab
  //TODO: リファクタ
  const tab = () => {
    const tabLinks = document.querySelectorAll('.js-tab-link');
    const tabPanels = document.querySelectorAll('.js-tab-panel');
    tabLinks.forEach((tabLink) => {
      tabLink.addEventListener('click', (e) => {
        const target = e.target.dataset.target;
        tabLinks.forEach((e) => {
          e.classList.remove('is-select');
        }, false);
        e.target.classList.add('is-select');
        tabPanels.forEach((e) => {
          e.classList.remove('is-active');
        }, false);
        document.getElementById(target).classList.add('is-active');
        localStorage.setItem('selectedProject', target);
      }, false);

    });
  }
  tab();
  const selectedProject = localStorage.getItem('selectedProject');
  const project = document.querySelector(`[data-target="${selectedProject}"]`);
  if (project) {
    project.click();
  } else {
    const firstTabLink = document.querySelector('.js-tab-link');
    firstTabLink && firstTabLink.click();
  }




  //dropdown menu
  const dropDownMenu = () => {
    const dropDowns = document.querySelectorAll('.js-dropdown');
    dropDowns.forEach((dropDown) => {
      const toggle = dropDown.querySelector('.js-dropdown-toggle');
      toggle.addEventListener('click', (e) => {
        const menu = dropDown.querySelector('.js-dropdown-menu');
        e.target.classList.toggle('is-show');
      }, false);
    });
  }
  dropDownMenu();

  //help用 tooltip
  const helpTooltip = () => {
    // 注釈のラベル項目の動的追加対策でwindowにしています↓
    window.addEventListener('click', (e) => {
      if (e.target && e.target.classList.contains('js-help-tooltip-toggle')) {
        e.target.classList.toggle('is-show');
      }
    })
  }
  helpTooltip();

    // copy text
    const clipCopy = (e) => {
      const copytext = document.getElementById(e.currentTarget.dataset.target);
      try {
          const range = document.createRange();
          range.selectNodeContents(copytext);
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
          document.execCommand('copy');
          selection.removeAllRanges();
          alert("URLをコピーしました。");
      } catch (e) {
          alert("このブラウザでは対応していません。");
      }
  };
  const copyBtns = document.querySelectorAll('.js-copyButton');
  copyBtns.forEach((btn) => {
    btn.addEventListener( 'click', (e) => clipCopy(e) );
  });
});
require("trix")
require("@rails/actiontext")